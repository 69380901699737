import { format, formatDistance, formatDistanceToNow, subDays } from 'date-fns'
import { ko } from 'date-fns/locale'
import { DateTime } from 'luxon'

export const formatDate = (date: Date) => {
  return format(date, 'yyyy.MM.dd', {
    locale: ko
  })
}
export const timeAgo = (date: string) => {
  return formatDistanceToNow(new Date(date), { locale: ko })
}

export function bookTimeAgo(date: DateTime, now: DateTime = DateTime.now()) {
  const diff = now.diff(date, 'minute').minutes;

  if (diff < 60) {
    return `방금 전`;
  }

  const diffHour = Math.floor(now.diff(date, 'hour').hours);

  if (diffHour < 24) {
    return `${diffHour}시간 전`;
  }

  const diffDays = Math.floor(now.diff(date, 'day').days);
  if (diffDays < 4) {
    return `${diffDays}일 전`;
  }

  return null
}